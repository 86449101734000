import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";
import Page from "../components/Page";
import Seo from "../components/seo";
import Aside from "../components/Aside";
import * as P from "../components/Page/styles";
import SubAreas from "../components/SubAreas";

const ConsumerRights = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Direito do Consumidor"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          { page: "/direito-do-consumidor", name: "Direito do Consumidor" },
        ]}
      >
        {isDesktop && <Aside type="consumer-law" withContact />}
        <P.Article>
          <p>
            Os consumidores possuem dezenas de direitos, sempre dependendo do
            tipo de negócio que estejam realizando.
          </p>
          <p>
            Estamos trazendo alguns, pouquíssimos, casos, pois seria impossível
            enumerar todos, desta forma, se você acha que foi lesado em algum
            direito que você acredita que teria, faça{" "}
            <P.ContactLink
              href="https://wa.me/5561983002735"
              target="_blank"
              rel="noreferrer"
            >
              contato pelo WhatsApp
            </P.ContactLink>{" "}
            e teremos prazer em sanar suas dúvidas.
          </p>
          <P.Subtitle>
            Indenizações contra inscrição indevida em órgãos de proteção de
            crédito (SPC/SERASA)
          </P.Subtitle>
          <p>
            Inscrições indevidas em órgãos de proteção de crédito são as
            situações tipicamente chamadas de “ficar com o nome sujo”. Embora, o
            problema geralmente decorra de uma dívida não paga, também é
            possível que a inscrição tenha surgido de uma circunstância em que a
            empresa não tinha direito de inscrever o nome do consumidor.
          </p>
          <p>
            Nestes casos, as consequências da inscrição indevida podem ser
            graves. Afinal, as pessoas que têm seu nome inscrito em órgãos de
            proteção de crédito têm seu direito ao crédito restrito. Isso
            significa não poder tirar empréstimos, não poder parcelas e, até
            mesmo.
          </p>
          <p>
            Obviamente, isso gera consequências para quem realizou a inscrição
            indevida. Já é consenso estabelecido nos órgãos judiciais que
            inscrições indevidas geram danos morais, além da reparação de
            eventuais prejuízos econômicos causados. Trata-se de um tipo de caso
            bastante direto, de pleito consolidado no ordenamento jurídico
            brasileiro.
          </p>
          <p>
            Não apenas isso, se por um acaso houve algum desconto em sua conta,
            em razão dessa dívida que sequer deveria existir, também nasce o
            direito de receber de volta o valor que foi indevidamente
            descontado, mas não apenas isso, o consumidor terá o direito de
            receber esse valor de forma dobrada.
          </p>
          <P.Subtitle>Indenizações contra cobranças indevidas </P.Subtitle>
          <p>
            Assim como a inscrição indevida em órgãos de proteção de crédito
            gera indenizações em potencial, as cobranças indevidas seguem o
            mesmo rumo. Quanto mais grave a ação tomada pela empresa para
            realizar a cobrança de maneira indevida, maior tende a ser o tipo de
            consequência jurídica.
          </p>
          <p>
            Isso também se aplica para cobranças indevidas ou abusivas vindas de
            instituições bancárias. Neste caso, a situação costuma ser
            especialmente mais grave, pois bancos costumam ter acesso a débitos
            diretos da conta do seu cliente, o que potencializa as consequências
            na vida deste cliente e, por consequência, a indenização
            potencialmente devida. E como já citado, o valor descontado deverá
            ser ressarcido em dobro.
          </p>
          <P.Subtitle>Associação e empréstimos não solicitados</P.Subtitle>
          <p>
            Outro fato que tem incomodado muita gente, em especial aposentados,
            são as associações indevidas, alguma associação, seja ela de idosos
            ou outro tipo. Simplesmente associa o aposentado, e começa a
            realizar descontos mensais em seu benefício.
          </p>
          <p>
            É muito importante está sempre de olho no extrato de pagamento do
            INSS, pois lá aparecem todos os descontos que são feitos no seu
            benefício, e existem muitos bancos e financeiras que estão fazendo
            vendas casadas, onde o cliente busca um empréstimo, e dentro da
            documentação para o empréstimo, eles incluem o de alguma associação,
            e assim dá início aos descontos.
          </p>
          <p>
            Outro fato que vem ocorrendo com bastante regularidade são os
            empréstimos não solicitados, ou golpes propriamente ditos, em que
            são realizados empréstimos no nome do idoso sem o seu consentimento,
            ou com base em uma propaganda enganosa, e que assim geram diversos
            descontos.
          </p>
        </P.Article>
        {!isDesktop && <Aside type="consumer-law" withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Direito do Consumidor"
    description="Atendimento especializado em Direito do Consumidor. Defenda seus direitos em questões de compras, contratos e serviços, com apoio jurídico eficiente e confiável."
  />
);

export default ConsumerRights;
